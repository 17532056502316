import { MDXProvider } from '@mdx-js/react'
import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import { InfoBlock, WarningBlock } from 'components/helpers'
import MyImage from 'components/MyImage'
import MyVideo from 'components/MyVideo'
import { Link, PageProps } from 'gatsby'
import { DateTime } from 'luxon'
import React from 'react'

type BlogFrontmatterProps = {
  frontmatter: {
    title: string,
    intro: string,
    date: string,
    image: string,
  }
}

const shortcodes = { Link, MyImage, MyVideo, WarningBlock, InfoBlock }

/**
 *  Blog post layout.
 */
export default function MDXBlogPostLayout(props: PageProps<{}, BlogFrontmatterProps>) { //  #wxWW3#
  const { uri, children, pageContext: { frontmatter: { title, intro, date, image } } } = props

  return (
    <div>
      <Header fixed />
      <main className="py-24 container-w">
        <div className="sm:grid grid-cols-6 gap-4">
          <dl className="grid-1">
            <dt className="sr-only">Published on</dt>
            <dd className="whitespace-nowrap text-gray-400
            prose prose-sm lg:prose-lg xl:prose-xl
            mt-0.5 sm:mt-1
            text-right
            ">
              <time dateTime={date}>{DateTime.fromISO(date).setLocale('en-en').toLocaleString(DateTime.DATE_MED)}</time>
            </dd>
          </dl>

          <article className="col-span-5 prose sm:prose-sm md:prose-md lg:prose-lg">
            <h2 className="mb-0">{title}</h2>
            {image && <MyImage src={image} alt="XCode settings"/>}
            <p className="lead">{intro}</p>
            <hr />
            <MDXProvider components={shortcodes}>{children}</MDXProvider>
          </article>
        </div>
      </main>
      <Footer />
    </div>
  )
}
