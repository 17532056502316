import React from 'react'

type Props = {
  src: string,
  autoPlay?: boolean,
  loop?: boolean,
}

export default function MyVideo(props: Props) {
  const { src, autoPlay = true, loop = true } = props
  return <video className="rounded-2xl overflow-clip my-shadow" muted width="100%" autoPlay={autoPlay} loop={loop}>
    <source
      src={src}
      type="video/mp4" />
    Sorry, your browser doesn't support embedded videos.
  </video>

}
