import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/layouts/MDXBlogPostLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const MyImage = makeShortcode("MyImage");
const MyVideo = makeShortcode("MyVideo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Embedding live views from your code right into your notes was designed to be `}<Link to="/documentation/adding-live-code-views" mdxType="Link">{`as simple as possible`}</Link>{`: paste the token in your code, adjust how many lines how you want to show, done.`}</p>
    <p>{`That's fine, but sometimes it would be nice to provide more context as to where this code lives within the file.`}</p>
    <p>{`With Smart Contexts, you are now able to, and here is what it looks like:`}</p>
    <div className="flex gap-4">
  <MyImage src="note-without-context.png" alt="smart-contexts" mdxType="MyImage">
    A note without smart contexts.
  </MyImage>
  <MyImage src="note-with-context.png" alt="smart-contexts" mdxType="MyImage">
    That same note, with smart context.
  </MyImage>
    </div>
    <p>{`How does it work?`}</p>
    <ul>
      <li parentName="ul">{`Check the "Use Context" option`}</li>
      <li parentName="ul">{`Type in some text that matches a line above the token that you want to reveal.`}</li>
      <li parentName="ul">{`Repeat with more, if required.`}</li>
    </ul>
    <MyVideo src="https://s3.fr-par.scw.cloud/codestory-web/media/SmartContexts.mp4" mdxType="MyVideo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      